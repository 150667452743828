export const apiGetOptions: RequestInit = {
	method: "GET",
	mode: "cors",
	headers: {
	}
}

export const apiPostOptions = (body: string): RequestInit => {
	return {
		method: "POST",
		mode: "cors",
		body: body
	}
}