import { apiGetOptions } from "../../constants/apiConstants";
import { LiveGamePerformanceData } from "../../dataTypes/liveGamePerformanceData";

const nflGetLiveGamePerformanceUrl = (gameId: string): string => {
    return `https://m9p4l1jnig.execute-api.us-east-1.amazonaws.com/prod/${gameId}`
}

export const getNFLLiveGamePerformance = async (gameId: string): Promise<LiveGamePerformanceData> => {
    let gamePerformance: LiveGamePerformanceData = null
    
    await fetch(nflGetLiveGamePerformanceUrl(gameId), apiGetOptions)
        .then(response => {
            return response.json()
        })
        .then(data => {
            gamePerformance = data
        })
        .catch(err => console.log(err));
    return gamePerformance
}