import { BetEntry } from "../dataTypes/betEntryData";
import { LiveGamePerformanceData, LiveTeamPerformanceData } from "../dataTypes/liveGamePerformanceData";
import { LivePlayerPerformanceData } from "../dataTypes/livePlayerPerformanceData";

export const getCurrentValueForPlayerBet = (betEntry: BetEntry, playerPerformance: LivePlayerPerformanceData): number | undefined => {
    
    switch (betEntry.type.name) {
        // Passing
        case "Passing Yards":
            return playerPerformance?.playerPassingPerformance?.passingYards
        case "Passing Touchdowns":
            return playerPerformance?.playerPassingPerformance?.passingTouchdowns
        case "Passing Attempts":
            return playerPerformance?.playerPassingPerformance?.passingAttempts
        case "Passing Completions":
            return playerPerformance?.playerPassingPerformance?.passingCompletions

        // Rushing
        case "Rushing Yards":
            return playerPerformance?.playerRushingPerformance?.rushingYards
        case "Rushing Touchdowns":
            return playerPerformance?.playerRushingPerformance?.rushingTouchdowns
        case "Rushing Attempts":
            return playerPerformance?.playerRushingPerformance?.rushingAttempts
        case "Rushing Longest":
            return playerPerformance?.playerRushingPerformance?.rushingLongest

        // Receiving
        case "Receiving Yards":
            return playerPerformance?.playerReceivingPerformance?.receivingYards
        case "Receiving Touchdowns":
            return playerPerformance?.playerReceivingPerformance?.receivingTouchdowns
        case "Receptions":
            return playerPerformance?.playerReceivingPerformance?.receivingReceptions
        case "Receiving Longest":
            return playerPerformance?.playerReceivingPerformance?.receivingLongest
    }

    return 0
}


export const getCurrentValueForTeamBet = (betEntry: BetEntry, gamePerformance: LiveGamePerformanceData): number | undefined => {

    let teamId = betEntry.target.teamData!.teamId;

    if(gamePerformance?.gameAwayTeamPerformance == undefined || gamePerformance.gameHomeTeamPerformance == undefined){
        return 0
    }

    let teamPerformances = [gamePerformance!.gameAwayTeamPerformance, gamePerformance!.gameHomeTeamPerformance]
    let teamPerformance = teamPerformances.find(team => team.teamId == teamId)
    let opponentPerformance = teamPerformances.find(team => team.teamId != teamId)

    switch (betEntry.type.name) {
        // TODO
        case "Team to Win":
            return 0
        // TODO
        case "Team Spread":
            return (opponentPerformance!.teamScore - teamPerformance!.teamScore)
        case "Total Team Points":
            return teamPerformance?.teamScore
        case "First Quarter Team Points":
            return teamPerformance?.teamFirstQuarterScore
        case "Second Quarter Team Points":
            return teamPerformance?.teamSecondQuarterScore
        case "Third Quarter Team Points":
            return teamPerformance?.teamThirdQuarterScore
        case "Fourth Quarter Team Points":
            return teamPerformance?.teamFourthQuarterScore

    }

}