export const calcChanceToHit = (currentValue: number, targetValue: number, percentCompleted: number) => {
    let rateOfProgress = (currentValue / percentCompleted) * 100
    let remainingProgress = rateOfProgress * (100 - percentCompleted) / 100
    let estimatedProgress = currentValue + remainingProgress
    let probability = Math.min(1, estimatedProgress / targetValue)
    return probability * 100
}

export const chanceToHitText = (chanceToHit: number) => {
    if (chanceToHit > 90) {
        return 'Very Likely'
    }
    else if (chanceToHit > 65) {
        return 'Likely'
    }
    else if (chanceToHit > 45) {
        return 'Toss Up'
    }
    else if (chanceToHit > 15) {
        return 'Unlikely'
    }
    else {
        return 'Very Unlikely'
    }
}