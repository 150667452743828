import { Button, Input, Space, theme, Layout, Typography, Image, List, Card, Progress, Dropdown } from 'antd';
import React, { useState } from 'react';
import { EvaluatedBetEntry } from '../dataTypes/betEntryData';
import { primaryCardStyle, secondaryCardStyle } from '../styles/styleSheet';
import { chanceToHitText } from '../util/betCalcUtil';

const betEntryCardStyle = {
    width: '80vw'
}

const BetEntryView: React.FC<{ evaluatedBetEntry: EvaluatedBetEntry, deleteHandler: Function }> = ({ evaluatedBetEntry, deleteHandler }) => {
    const [activeTabKey, setActiveTabKey] = useState<any | null>('info');

    const onBetEntryViewTabChange = (activeTabKey: any) => {
        setActiveTabKey(activeTabKey);
    }

    const handleDelete = () => {
        deleteHandler(evaluatedBetEntry);
    }

    const constructTitleString = () => {
        // Player bet type
        if (evaluatedBetEntry.betEntry.target.playerData != undefined){
            return `${evaluatedBetEntry.betEntry.target.playerData.playerName} ${evaluatedBetEntry.betEntry.overUnder} ${evaluatedBetEntry.betEntry.value} ${evaluatedBetEntry.betEntry.type.name}`
        }
        // Team bet type
        else if (evaluatedBetEntry.betEntry.target.teamData != undefined){
            return `${evaluatedBetEntry.betEntry.target.teamData.teamName} ${evaluatedBetEntry.betEntry.overUnder} ${evaluatedBetEntry.betEntry.value} ${evaluatedBetEntry.betEntry.type.name}`
        }
    }

    const constructContentString = () => {
        // Player bet type
        if (evaluatedBetEntry.betEntry.target.playerData != null){
            return `${evaluatedBetEntry.currentValue.toString()} / ${evaluatedBetEntry.betEntry.value} ${evaluatedBetEntry.betEntry.type.name}`
        }
        // Team bet type
        else if (evaluatedBetEntry.betEntry.target.teamData != null){
            return `${evaluatedBetEntry.currentValue.toString()} / ${evaluatedBetEntry.betEntry.value} ${evaluatedBetEntry.betEntry.type.name}`
        }
    }

    type contentViewMap = {
        [key: string]: any
    }

    const infoView: React.ReactElement =
        <Space size={'large'} direction='horizontal'>
            <Space size={'large'} direction='vertical'>
                <Typography>
                    {constructContentString()}
                </Typography>
                <Typography>
                    {`Chance to hit: ${chanceToHitText(evaluatedBetEntry.chanceToHit)}`}
                </Typography>
            </Space>
            <Progress type='circle' size={50} percent={Math.floor((evaluatedBetEntry.currentValue / Number(evaluatedBetEntry.betEntry.value)) * 100)} />
        </Space >

    const hasNotStartedView: React.ReactElement =
        <Space size={'large'} direction='horizontal'>
            <Typography>
                Game has not started yet
            </Typography>
        </Space >

    const actionView: React.ReactElement =
        <Space size={'large'} direction='horizontal' >
            <Button type='primary' onClick={handleDelete}>
                Delete
            </Button>
        </Space >

    const contentList: contentViewMap = {
        // If the game has not started yet check
        info: evaluatedBetEntry.currentValue.toString() !== 'NaN' ? infoView : hasNotStartedView,
        actions: actionView
    }
    return (
        <Card
            style={secondaryCardStyle}
            size="small"
            title={constructTitleString()}
            tabProps={{style: primaryCardStyle}}
            headStyle={secondaryCardStyle}
            onTabChange={onBetEntryViewTabChange}
            tabList={[{ key: 'info', tab: 'Info' }, { key: 'actions', tab: 'Actions' }]}>
            {contentList[activeTabKey]}
        </Card>
    )
}

export default BetEntryView;