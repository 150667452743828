import { apiGetOptions } from "../../constants/apiConstants";
import { TeamData } from "../../dataTypes/teamData";

const nflGetTeamUrl = (teamId: string): string => {
    return `https://b0tqh63evh.execute-api.us-east-1.amazonaws.com/prod/${teamId}`
}

export const getNFLTeam = async (teamId: string | undefined): Promise<TeamData> => {
    let team: TeamData = null
    if (teamId === undefined) {
        return team
    }
    await fetch(nflGetTeamUrl(teamId), apiGetOptions)
        .then(response => {
            return response.json()
        })
        .then(data => {
            team = data
        })
        .catch(err => console.log(err));
    return team
}