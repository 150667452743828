export const primaryColor = '#FBFCFF'

export const secondaryColor = '#7FC8F8'

export const tertiaryColor = '#272727'

const isPrimaryDark = false
export const primaryTextColor = isPrimaryDark ? '#FFFFFF' : '#000000'

// Buttons
export const primaryButtonStyle = {
    backgroundColor: secondaryColor,
    color: primaryTextColor
}

export const secondaryButtonStyle = {
    backgroundColor: secondaryColor,
    color: primaryColor
}

export const tertiaryButtonStyle = {
    backgroundColor: tertiaryColor,
    color: primaryColor,
}

// Text
export const primaryTextStyle = {
    color: primaryTextColor,
}

export const secondaryTextStyle = {
    color: secondaryColor,
}

// Cards
export const primaryCardStyle = {
    backgroundColor: secondaryColor,
    color: primaryTextColor,
    borderRadius: '10px',
}

export const secondaryCardStyle = {
    backgroundColor: secondaryColor,
    color: primaryTextColor,
    borderRadius: '10px',
    width: '80vw'
}
