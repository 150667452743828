import { apiGetOptions } from "../../constants/apiConstants";
import { DateGameData } from "../../dataTypes/dateGameData";

const nflGamesOnDateUrl = (day: string, month: string, year: string): string => {
    return `https://miiyhelkmd.execute-api.us-east-1.amazonaws.com/prod/${day}-${month}-${year}`
}

export const getNFLGamesOnDate = async (day: string, month: string, year: string): Promise<DateGameData> => {
    let games: DateGameData = null
    await fetch(nflGamesOnDateUrl(day, month, year), apiGetOptions)
        .then(response => {
            return response.json()
        })
        .then(data => {
            games = data
        })
        .catch(err => console.log(err));
    return games
}