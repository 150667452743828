import { apiGetOptions } from "../../constants/apiConstants";
import { LivePlayerPerformanceData } from "../../dataTypes/livePlayerPerformanceData";

const nflGetLivePlayerPerformanceUrl = (playerId: string, gameId: string): string => {
    return `https://hdoo287hb7.execute-api.us-east-1.amazonaws.com/prod/${playerId}-${gameId}`
}

export const getNFLLivePlayerPerformance = async (playerId: string, gameId: string): Promise<LivePlayerPerformanceData> => {
    let playerPerformance: LivePlayerPerformanceData = null
    
    await fetch(nflGetLivePlayerPerformanceUrl(playerId, gameId), apiGetOptions)
        .then(response => {
            return response.json()
        })
        .then(data => {
            playerPerformance = data
        })
        .catch(err => console.log(err));
    return playerPerformance
}