import { Button, Input, Space, theme, Layout, Typography, Image, List, Checkbox } from 'antd';
import BetEntryView from './betEntryView';
import NewBetButton from './newBetButton';
import { useEffect, useState } from 'react';
import { BetEntry, EvaluatedBetEntry } from '../dataTypes/betEntryData';
import { getNFLLivePlayerPerformance } from '../apiDataRetrieval/nfl/nflGetLivePlayerPerformance';
import { getCurrentValueForPlayerBet, getCurrentValueForTeamBet } from '../util/betTypeUtil';
import { UserBetData } from '../dataTypes/userBetsData';
import { getNFLUserBetData, postNFLUserBetData } from '../apiDataRetrieval/nfl/nflUserBetData';
import { primaryButtonStyle, tertiaryButtonStyle } from '../styles/styleSheet';
import { getNFLLiveGamePerformance } from '../apiDataRetrieval/nfl/nflGetLiveGamePerformance';
import { calcChanceToHit } from '../util/betCalcUtil';

const filtersBarStyle = {
    width: '80vw',
    justifyContent: 'right',
    align: 'center',
    paddingTop: '1vh',
}

const listStyle = {

}

const BetsView = ({ username }: any) => {

    const [betsViewData, setBetsviewData] = useState<BetEntry[]>([])
    const [evaluatedBetsData, setEvaluatedBetsData] = useState<EvaluatedBetEntry[]>([])

    useEffect(() => {
        if (username != '') {
            getUserBets(username)
        }
    }, [username])

    useEffect(() => {
        evaluateAllBets()
    }, [betsViewData])

    const handleNewBet = async (newBetEntry: BetEntry) => {
        console.log(newBetEntry)
        setBetsviewData([...betsViewData, newBetEntry]);
        postUserBets([...betsViewData, newBetEntry])
    }

    const handleDeleteBet = (betEntryToDelete: EvaluatedBetEntry) => {
        setBetsviewData(betsViewData.filter(bet => bet.betId !== betEntryToDelete.betEntry.betId))
        postUserBets(betsViewData.filter(bet => bet.betId !== betEntryToDelete.betEntry.betId))
    }

    const getUserBets = async (userId: string) => {
        let userBetData: UserBetData = null;
        setBetsviewData([])
        await getNFLUserBetData(userId)
            .then(data => {
                userBetData = data;
                if (userBetData != null) {
                    setBetsviewData(userBetData!.betEntries);
                }
            })
            .catch(err => console.log(err));
    }

    const postUserBets = async (currentBets: BetEntry[]) => {
        if (username == '') {
            return;
        }

        let userBetData: UserBetData = {
            userId: username,
            betEntries: currentBets
        };

        await postNFLUserBetData(userBetData)
            .catch(err => console.log(err));
    }

    async function evaluateBet(betEntry: BetEntry): Promise<EvaluatedBetEntry> {
        let outputEvaluatedBetEntry: EvaluatedBetEntry = {
            betEntry: betEntry,
            currentValue: 0,
            chanceToHit: 0
        };

        if (betEntry.type.category == 'Player') {
            const playerAPIDataPromise = getNFLLivePlayerPerformance(
                betEntry.target.playerData!.playerId.toString(),
                betEntry.game!.gameId
            );

            const gameAPIDataPromise = getNFLLiveGamePerformance(
                betEntry.game!.gameId
            );

            // Await both API calls concurrently using Promise.all
            const [playerAPIData, gameAPIData] = await Promise.all([
                playerAPIDataPromise,
                gameAPIDataPromise,
            ]);

            let currentValue = getCurrentValueForPlayerBet(betEntry, playerAPIData);

            let evaluatedBetEntry: EvaluatedBetEntry = {
                betEntry: betEntry,
                currentValue: Number(currentValue),
                chanceToHit: calcChanceToHit(
                    Number(currentValue),
                    betEntry.value,
                    gameAPIData!.gamePercentCompleted
                )
            }
            outputEvaluatedBetEntry = evaluatedBetEntry;
        }
        else if (betEntry.type.category == 'Team') {
            await getNFLLiveGamePerformance(
                betEntry.game!.gameId
            ).then(
                data => {
                    let currentValue = getCurrentValueForTeamBet(betEntry, data);

                    let evaluatedBetEntry: EvaluatedBetEntry = {
                        betEntry: betEntry,
                        currentValue: Number(currentValue),
                        chanceToHit: calcChanceToHit(
                            Number(currentValue),
                            betEntry.value,
                            data!.gamePercentCompleted
                        )
                    }
                    outputEvaluatedBetEntry = evaluatedBetEntry;
                }
            )
        }



        return outputEvaluatedBetEntry
    }

    const evaluateAllBets = async () => {
        let newEvaluatedBetsData: EvaluatedBetEntry[] = [];
        await Promise.all(betsViewData.map(async bet => {
            await evaluateBet(bet)
                .then(data => newEvaluatedBetsData.push(data));
        }))
        setEvaluatedBetsData(newEvaluatedBetsData)
    }

    const refreshBets = async () => {
        let newEvaluatedBetsData: EvaluatedBetEntry[] = [];
        await Promise.all(evaluatedBetsData.map(async bet => {
            await evaluateBet(bet.betEntry)
                .then(data => newEvaluatedBetsData.push(data));
        }))
        setEvaluatedBetsData(newEvaluatedBetsData)
    }

    return (
        <Space size={0} direction="vertical" align='center'>
            <Space direction='horizontal'>
                <Button style={tertiaryButtonStyle} onClick={refreshBets}>
                    Refresh Bets
                </Button>
                <NewBetButton handleNewBet={handleNewBet} />
            </Space>
            <List
                itemLayout="vertical"
                pagination={{
                    pageSize: 10,
                    position: 'bottom',
                    align: 'center',
                    hideOnSinglePage: true
                }}
                style={listStyle}
                dataSource={evaluatedBetsData}
                renderItem={(item) => (
                    <Space>
                        <BetEntryView evaluatedBetEntry={item} deleteHandler={handleDeleteBet} />
                    </Space>
                )}
            />
        </Space>
    );
}

export default BetsView;