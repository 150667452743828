import HeaderContent from "./components/headerContent";
import { Button, ConfigProvider, Input, Space, Layout } from 'antd';
import React from 'react';
import BetsView from './components/betsView';
import { UserAuthButton } from "./components/userAuth";
import { primaryColor, secondaryColor } from "./styles/styleSheet";

const headerStyle: React.CSSProperties = {
    position: 'sticky',
    top: 0,
    zIndex: 1,
    display: 'flex',
    alignItems: 'center',
    height: '15vh',
    backgroundColor: secondaryColor,
};

const contentStyle: React.CSSProperties = {
    textAlign: 'center',
    backgroundColor: primaryColor,
};

const layoutStyle: React.CSSProperties = {
    height: '100vh',
}

const MainPage = () => {
    const { Header, Content } = Layout;

    const [username, setUsername] = React.useState<string>('');

    const handleUsernameChange = (username: string) => {
        console.log(username)
        setUsername(username);
    }

    return (
        <ConfigProvider>
            <Layout style={layoutStyle}>
                <Header style={headerStyle}>
                    <HeaderContent />
                </Header>
                <Content style={contentStyle}>
                    <Space direction="vertical">
                        <UserAuthButton handleUsernameChange={handleUsernameChange}/>
                        <BetsView username={username}  />
                    </Space>
                </Content>
            </Layout>
        </ConfigProvider>
    );
}

export default MainPage;