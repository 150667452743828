import { apiGetOptions, apiPostOptions } from "../../constants/apiConstants";
import { UserBetData } from "../../dataTypes/userBetsData";

const nflUserBetsUrl = (userId: string): string => {
    return `https://wyqohjmq7c.execute-api.us-east-1.amazonaws.com/prod/${userId}`
}

export const getNFLUserBetData = async (userId: string): Promise<UserBetData> => {
    let userData: UserBetData = null
    await fetch(nflUserBetsUrl(userId), apiGetOptions)
        .then(response => {
            return response.json()
        })
        .then(data => {
            userData = data
        })
        .catch(err => console.log(err));
    return userData
}


export const postNFLUserBetData = async (betData: UserBetData): Promise<void> => {
    await fetch(nflUserBetsUrl(betData!.userId),
        apiPostOptions(JSON.stringify(betData)))
        .then(response => {
            return response.json()
        })
        .then(data => {
            console.log(data)
        })
        .catch(err => console.log(err));
}