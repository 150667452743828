import { NFLBetType } from "../dataTypes/nflBetTypeData"

export const nflBetTypes = (category: string): NFLBetType[] => {
    // null check
    if (category === null) {
        return []
    }
    
    return allBetTypes.filter(
        betType => betType.category.toLowerCase() === category.toLowerCase()
    )
}

export const getBetTypeFromName = (name: string): NFLBetType => {
    let outputBetType: NFLBetType = {
        name: "",
        category: "",
        availablePositions: []
    }
    allBetTypes.forEach(betType => {
        if (betType.name === name) {
            outputBetType = betType
        }
    })
    return outputBetType
}

export const nflBetTypesForUI = (category: string): any[] => {
    let uiBetTypes: any[] = []
    nflBetTypes(category).forEach(betType => {
        uiBetTypes.push({
            label: betType.name,
            value: betType.name
        })
    })
    return uiBetTypes
}

// Yards
const passingYards: NFLBetType = {
    name: "Passing Yards",
    category: "Player",
    availablePositions: ["QB"]
}

const rushingYards: NFLBetType = {
    name: "Rushing Yards",
    category: "Player",
    availablePositions: ["QB", "RB"]
}

const receivingYards: NFLBetType = {
    name: "Receiving Yards",
    category: "Player",
    availablePositions: ["RB", "WR", "TE"]
}

// Touchdowns
const passingTouchdowns: NFLBetType = {
    name: "Passing Touchdowns",
    category: "Player",
    availablePositions: ["QB"]
}

const rushingTouchdowns: NFLBetType = {
    name: "Rushing Touchdowns",
    category: "Player",
    availablePositions: ["QB", "RB"]   
}

const receivingTouchdowns: NFLBetType = {
    name: "Receiving Touchdowns",
    category: "Player",
    availablePositions: ["RB", "WR", "TE"]   
}

// Attempts
const passingAttempts: NFLBetType = {
    name: "Passing Attempts",
    category: "Player",
    availablePositions: ["QB"]
}

const rushingAttempts: NFLBetType = {
    name: "Rushing Attempts",
    category: "Player",
    availablePositions: ["QB", "RB"]
}

const receivingReceptions: NFLBetType = {
    name: "Receptions",
    category: "Player",
    availablePositions: ["RB", "WR", "TE"]   
}

// Longest
const rushingLongest: NFLBetType = {
    name: "Rushing Longest",
    category: "Player",
    availablePositions: ["QB", "RB"]
}

const receivingLongest: NFLBetType = {
    name: "Receiving Longest",
    category: "Player",
    availablePositions: ["RB", "WR", "TE"]
}

// Passing Misc
const passingCompletions: NFLBetType = {
    name: "Passing Completions",
    category: "Player",
    availablePositions: ["QB"]
}

// Teams
const teamToWin: NFLBetType = {
    name: "Team To Win",
    category: "Team",
}

const teamSpread: NFLBetType = {
    name: "Team Spread",
    category: "Team",
}

const totalTeamPoints: NFLBetType = {
    name: "Total Team Points",
    category: "Team",
}

const firstQuarterTeamPoints: NFLBetType = {
    name: "First Quarter Team Points",
    category: "Team",
}

const secondQuarterTeamPoints: NFLBetType = {
    name: "Second Quarter Team Points",
    category: "Team",
}


const thirdQuarterTeamPoints: NFLBetType = {
    name: "Third Quarter Team Points",
    category: "Team",
}

const fourthQuarterTeamPoints: NFLBetType = {
    name: "Fourth Quarter Team Points",
    category: "Team",
}

const allBetTypes: NFLBetType[] = [
    passingYards,
    rushingYards,
    receivingYards,
    passingTouchdowns,
    rushingTouchdowns,
    receivingTouchdowns,
    passingAttempts,
    rushingAttempts,
    receivingReceptions,
    rushingLongest,
    receivingLongest,
    passingCompletions,
    totalTeamPoints,
    firstQuarterTeamPoints,
    secondQuarterTeamPoints,
    thirdQuarterTeamPoints,
    fourthQuarterTeamPoints,
    teamToWin,
    teamSpread
]