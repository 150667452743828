import { Button, Card, Input, Space, Typography } from "antd"
import Popup from "reactjs-popup"
import React, { useState } from 'react';
import { Auth, Amplify } from "aws-amplify";
import { primaryButtonStyle, primaryCardStyle, primaryTextStyle, secondaryButtonStyle, tertiaryButtonStyle } from "../styles/styleSheet";

export const CognitoConfigAuth = {
    region: 'us-east-1',
    userPoolId: 'us-east-1_SoT4TxWZc',
    userPoolWebClientId: '4uqlgke6bske7lenc3ivj6sl4f'
}

export const UserAuthButton = ({handleUsernameChange}: any) => {

    Amplify.configure(CognitoConfigAuth)

    const [username, setUsername] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [code, setCode] = useState('')

    const [currentLoginState, setCurrentLoginState] = useState<string>('logIn')
    const [currentSignUpState, setCurrentSignUpState] = useState<string>('signIn')

    const handleSignIn = async () => {
        const result = await Auth.signIn(username, password)
        console.log(result)
        handleUsernameChange(username)
        setCurrentLoginState('logOut')
    }

    const handleSignOut = async () => {
        const result = await Auth.signOut()
        console.log(result)
        handleUsernameChange('')
        setCurrentLoginState('logIn')
    }

    const handleSignUp = async () => {
        const result = await Auth.signUp({
            username,
            password,
            attributes: {
                email
            },
            autoSignIn: {
                enabled: true
            }
        })
        setCurrentSignUpState('verify')
    }

    const handleCodeVerification = async () => {
        const result = await Auth.confirmSignUp(username, code)
        if(result == 'SUCCESS'){
            setCurrentSignUpState('signedIn')
        }
    }

    const signInUpView = <Card style={primaryCardStyle} headStyle={primaryTextStyle} title="Create Account">
        <Space direction="vertical">
            <Typography.Text style={primaryTextStyle}>
                Username:
            </Typography.Text>
            <Input placeholder="Username" onChange={e => setUsername(e.target.value)} />
            <Typography.Text style={primaryTextStyle}>
                Email:
            </Typography.Text>
            <Input placeholder="Email" type="email" onChange={e => setEmail(e.target.value.toLowerCase())} />
            <Typography.Text style={primaryTextStyle}>
                Password:
            </Typography.Text>
            <Input.Password placeholder="Password" onChange={e => setPassword(e.target.value)} />
            <Button style={tertiaryButtonStyle} onClick={handleSignUp}>Submit</Button>
        </Space>
    </Card>

    const verificationView = <Card title="Verify Email" headStyle={primaryTextStyle} style={primaryCardStyle}>
        <Space direction="vertical">
            <Typography.Text style={primaryTextStyle}>
                Verification Code:
            </Typography.Text>
            <Input placeholder="Verification Code" onChange={e => setCode(e.target.value)} />
            <Button style={tertiaryButtonStyle} onClick={handleCodeVerification}>Verify</Button>
        </Space>
    </Card>

    const loginView = <Card title="Login" headStyle={primaryTextStyle} style={primaryCardStyle}>
        <Space direction="vertical">
            <Typography.Text style={primaryTextStyle}>
                Username:
            </Typography.Text >
            <Input placeholder="Username" onChange={e => setUsername(e.target.value)} />
            <Typography.Text style={primaryTextStyle}>
                Password:
            </Typography.Text>
            <Input.Password placeholder="Password" onChange={e => setPassword(e.target.value)} />
            <Button style={tertiaryButtonStyle} onClick={handleSignIn}>Log In</Button>
        </Space>
    </Card>

    const logoutView = <Card title="Logout" headStyle={primaryTextStyle} style={primaryCardStyle}>
        <Space direction="vertical">
            <Typography.Text style={primaryTextStyle}>
                {`You are logged in as ${username}`}
            </Typography.Text>
            <Button style={tertiaryButtonStyle} onClick={handleSignOut}>Log Out</Button>
        </Space>
    </Card>

    const loginStateViewMap: Map<string, JSX.Element> = new Map([
        ['logIn', loginView],
        ['logOut', logoutView],
    ])

    const signUpStateViewMap: Map<string, JSX.Element> = new Map([
        ['signIn', signInUpView],
        ['signUp', signInUpView],
        ['verify', verificationView]
    ])

    return (
        <Space direction="horizontal">
            <Popup trigger={
                <Button style={tertiaryButtonStyle}>Create Account</Button>
            }>
                {signUpStateViewMap.get(currentSignUpState)}
            </Popup>
            <Popup trigger={
                <Button style={tertiaryButtonStyle}>Log In</Button>
            }>
                {loginStateViewMap.get(currentLoginState)}
            </Popup>
        </Space>
    )
}