import ReactDOM from 'react-dom/client';
import './index.css';
import MainPage from './mainPage';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <MainPage />
);
