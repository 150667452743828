import React, { useEffect, useState } from 'react';
import { Button, Modal, Typography, Space, Select, DatePicker, InputNumber, AutoComplete } from 'antd';
import { getNFLGamesOnDate } from '../apiDataRetrieval/nfl/nflGamesOnDate';
import { DateGameData, GameData, TeamGameData } from '../dataTypes/dateGameData';
import { PlayerRosterData, TeamData } from '../dataTypes/teamData';
import { getNFLTeam } from '../apiDataRetrieval/nfl/nflGetTeam';
import { getBetTypeFromName, nflBetTypesForUI } from '../constants/nflBetTypes';
import { NFLBetType } from '../dataTypes/nflBetTypeData';
import { BetEntry, BetTargetData } from '../dataTypes/betEntryData';
import { primaryButtonStyle } from '../styles/styleSheet';

const { Title } = Typography;

const leagueOptions = [
    { value: 'nfl', label: 'NFL' },
    { value: 'nhl', label: 'NHL', disabled: true },
    { value: 'nba', label: 'NBA', disabled: true },
    { value: 'mlb', label: 'MLB', disabled: true },
]

const betCategoryOptions = [
    { value: 'player', label: 'Player' },
    { value: 'team', label: 'Team' },
]

const overUnderOptions = [
    { value: 'over', label: 'Over' },
    { value: 'under', label: 'Under' },
]

const NewBetButton = ({ handleNewBet }: any) => {

    // data + corresponding ui states
    const [availableGames, setAvailableGames] = useState<DateGameData>(null)
    const [availableUIGames, setAvailableUIGames] = useState<any[]>([])

    const [availablePlayers, setAvailablePlayers] = useState<PlayerRosterData[]>([])
    const [availableUITargets, setAvailableUITargets] = useState<any[]>([])

    const [selectedGame, setSelectedGame] = useState<GameData | null>(null)

    // non ui-dependant states
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedDate, setSelectedDate] = useState<any | null>(null)
    const [selectedLeague, setSelectedLeague] = useState<any | null>(null)

    const [selectedBetTarget, setSelectedBetTarget] = useState<any | null>(null)
    const [selectedBetType, setSelectedBetType] = useState<NFLBetType>({} as NFLBetType)
    const [selectedBetCategory, setSelectedBetCategory] = useState<any | null>(null)
    const [selectedOverUnder, setSelectedOverUnder] = useState<any | null>(null)
    const [selectedTargetValue, setSelectedTargetValue] = useState<any | null>(null)

    // loading states
    const [isLoadingTargetsAPICall, setIsLoadingTargetsAPICall] = useState(false)
    const [isLoadingGamesAPICall, setIsLoadingGamesAPICall] = useState(false)

    useEffect(() => {
        if (selectedBetType.category == 'Player') {
            assignApplicablePlayers();
        }
        else if (selectedBetType.category == 'Team') {
            assignApplicableTeams();
        }

    }, [selectedBetType])

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleSubmit = () => {
        setIsModalOpen(false);
        const newBetEntry: BetEntry = {
            betId: Math.floor(Math.random() * 100000000).toString(),
            game: selectedGame,
            type: selectedBetType,
            target: constructBetTargetData(),
            overUnder: selectedOverUnder,
            value: selectedTargetValue
        }
        console.log(newBetEntry)
        handleNewBet(newBetEntry);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const handleLeagueChange = (value: any) => {
        setSelectedLeague(value)
        makeGamesAPICall();
    }

    const handleGameChange = (value: any) => {
        setSelectedGame(getGameFromID(value))
    }

    const handleDateChange = (value: any) => {
        setSelectedDate(value);
    }

    const handleBetTargetChange = (value: any) => {
        let betTarget = {}
        if (selectedBetCategory == 'player') {
            betTarget = getPlayerFromName(value)
        }
        else if (selectedBetCategory == 'team') {
            betTarget = getTeamFromName(value)
        }
        setSelectedBetTarget(betTarget)
    }

    const handleBetCategoryChange = (value: any) => {
        setSelectedBetCategory(value);
        console.log(value)
        if (value == 'player') {
            makeActivePlayersAPICall();
        }
        else if (value == 'team') {

        }

    }

    const handleBetTypeChange = (value: any) => {
        setSelectedBetType(getBetTypeFromName(value));
    }

    const handleOverUnderChange = (value: any) => {
        setSelectedOverUnder(value)
    }

    const handleTargetValueChange = (value: any) => {
        setSelectedTargetValue(value)
    }

    const makeGamesAPICall = async () => {
        setIsLoadingGamesAPICall(true)

        let games: DateGameData = await getNFLGamesOnDate(
            selectedDate["$D"],
            selectedDate["$M"] + 1,
            selectedDate["$y"]
        )

        setAvailableGames(games)
        setAvailableUIGames(convertGamesToUIGames(games))

        setIsLoadingGamesAPICall(false)
    }

    const convertGamesToUIGames = (games: DateGameData) => {
        if (games == null) {
            return []
        }

        let outputData: any[] = []
        games.games.forEach(game => {
            outputData.push({
                value: game?.gameId,
                label: game?.awayTeam?.teamName + " @ " + game?.homeTeam?.teamName,
            })
        });
        return outputData
    }

    const getGameFromID = (id: string) => {
        let outputGame: GameData = null
        availableGames?.games.forEach(game => {
            if (game?.gameId == id) {
                outputGame = game
            }
        });
        return outputGame
    }

    const makeActivePlayersAPICall = async () => {
        // null check for activeGame
        if (selectedGame == null) {
            return null
        }

        setIsLoadingTargetsAPICall(true)

        // proceed with populating players list
        let homeTeam: TeamData = await getNFLTeam(selectedGame.homeTeam?.teamId.toString())
        let awayTeam: TeamData = await getNFLTeam(selectedGame.awayTeam?.teamId.toString())

        let players: PlayerRosterData[] = [...homeTeam!.players, ...awayTeam!.players]
        setAvailablePlayers(players)

        setIsLoadingTargetsAPICall(false)
    }

    const convertTeamsToUITeams = (teams: TeamGameData[]) => {
        // null check
        if (teams == undefined) {
            return []
        }

        let outputData: any[] = []
        teams.forEach(team => {
            outputData.push({
                id: team?.teamId,
                label: team?.teamName,
                value: team?.teamName,
            })
        });

        console.log(outputData)
        return outputData
    }

    const assignApplicableTeams = () => {
        let teams: TeamGameData[] = [selectedGame!.awayTeam, selectedGame!.homeTeam]
        console.log(teams)
        setAvailableUITargets(convertTeamsToUITeams(teams))
    }

    const convertPlayersToUIPlayers = (players: PlayerRosterData[]) => {
        let outputData: any[] = []
        players.forEach(player => {
            outputData.push({
                id: player.playerId,
                label: player.playerName,
                value: player.playerName,
            })
        });
        return outputData
    }

    const assignApplicablePlayers = () => {
        let filteredPlayers = getAvailablePlayersForNFLBetType(
            selectedBetType,
            availablePlayers
        )

        // null check for filteredPlayers
        if (filteredPlayers == null) {
            return null
        }

        setAvailableUITargets(convertPlayersToUIPlayers(filteredPlayers))
    }

    const getAvailablePlayersForNFLBetType = (betType: NFLBetType, players: PlayerRosterData[]) => {
        return players.filter((player: PlayerRosterData) => {
            return betType!.availablePositions?.includes(player.playerPosition);
        });
    }

    const getPlayerFromName = (name: string) => {
        return availablePlayers?.filter((player: PlayerRosterData) => {
            return player.playerName == name;
        })[0]
    }

    const getTeamFromName = (name: string) => {
        let teams: TeamGameData[] = [selectedGame!.awayTeam, selectedGame!.homeTeam]
        return teams.filter((team: TeamGameData) => {
            return team!.teamName == name;
        })[0]
    }

    const constructBetTargetData = (): BetTargetData =>{
        if (selectedBetCategory == 'player') {
            return {
                playerData: selectedBetTarget,
                teamData: undefined
            }
        }
        else {
            return {
                playerData: undefined,
                teamData: selectedBetTarget
            }
        }
    }

    return (
        <>
            <Button style={primaryButtonStyle} onClick={showModal}>
                New Bet
            </Button>
            <Modal
                open={isModalOpen}
                onCancel={handleCancel}
                footer={[
                    <Button key="back" onClick={handleCancel}>
                        Cancel
                    </Button>,
                    <Button key="submit" type="primary" onClick={handleSubmit}>
                        Submit
                    </Button>,
                ]}
            >
                <Space direction='vertical' style={{ width: '100%' }}>
                    <Title level={3}>New Bet</Title>
                    <Title level={5}>Date</Title>
                    <DatePicker
                        style={{ width: '100%' }}
                        onChange={handleDateChange}
                    />
                    <Title level={5}>League</Title>
                    <Select
                        disabled={selectedDate === null}
                        style={{ width: '100%' }}
                        onChange={handleLeagueChange}
                        options={leagueOptions}
                    />
                    <Title level={5}>Game</Title>
                    <Select
                        disabled={(selectedLeague === null || isLoadingGamesAPICall === true)}
                        style={{ width: '100%' }}
                        onChange={handleGameChange}
                        options={availableUIGames}
                    />
                    <Title level={5}>Bet Category</Title>
                    <Select
                        disabled={selectedGame === null}
                        style={{ width: '100%' }}
                        onChange={handleBetCategoryChange}
                        options={betCategoryOptions}
                    />
                    <Title level={5}>Bet Type</Title>
                    <Select
                        disabled={(selectedBetCategory === null || isLoadingTargetsAPICall === true)}
                        style={{ width: '100%' }}
                        onChange={handleBetTypeChange}
                        options={nflBetTypesForUI(selectedBetCategory)}
                    />
                    <Title level={5}>Player / Team (rename)</Title>
                    <AutoComplete
                        disabled={selectedBetType.name === undefined}
                        style={{ width: '100%' }}
                        onChange={handleBetTargetChange}
                        options={availableUITargets}
                        filterOption={(inputValue, option: any) =>
                            option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                        }
                    />
                    <Title level={5}>Over / Under</Title>
                    <Select
                        disabled={selectedBetTarget === null}
                        style={{ width: '100%' }}
                        onChange={handleOverUnderChange}
                        options={overUnderOptions}
                    />
                    <Title level={5}>Target Value</Title>
                    <InputNumber
                        disabled={selectedBetTarget === null}
                        style={{ width: '100%' }}
                        onChange={handleTargetValueChange}
                        min={0}
                    />
                </Space>
            </Modal>
        </>
    );
}

export default NewBetButton;