import { Button, Input, Space, theme, Layout, Typography, Image } from 'antd';
import { primaryTextStyle } from '../styles/styleSheet';

const { Title } = Typography;

const HeaderContent = () => {
    return ( 
        <Space direction="horizontal">
            <Title level={1} style={primaryTextStyle}>
                Bet Tracker Live
            </Title>
        </Space>
    )
}

export default HeaderContent;